import bigwheel from 'bigwheel';
import routes from './routes.js';

/* ----------
create our framework instance
see https://github.com/bigwheel-framework/documentation/blob/master/quickstart.md#bigwheel-quick-start
---------- */
export default bigwheel((done) => {
  done({
    autoResize: false,
    // https://github.com/bigwheel-framework/documentation/blob/master/misc.md#overlap
    overlap: false,
    // https://github.com/bigwheel-framework/documentation/blob/master/routes-special.md#initsection
    // initSection: require('./sections/preloader'),
    routes,
  });
});
