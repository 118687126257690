import { Default } from './default.js';
import $ from 'jquery';

export class MakingOf extends Default {
  constructor(opt) {
    super(opt);
    this.slug = 'making-of';
  }

  init(req, done) {
    super.init(req, done);

    const $titles = $('.section--titre h2');
    const $navList = $('#js-content-nav__list');
    const $navItem = $('#js-content-nav__item').removeAttr('id').detach();

    $titles.each((i, title) => {
      const id = title.id;
      const txt = $(title).html();
      const $item = $navItem.clone();
      $item.find('a').attr('href', `#${id}`).html(txt);

      $navList.append($item);
    });
  }

  dataAdded(done) {
    super.dataAdded();
    done();
  }

  animateIn(req, done) {
    super.animateIn(req, done);
  }

  animateOut(req, done) {
    super.animateOut(req, done);
  }

  destroy(req, done) {
    super.destroy();
    done();
  }
}
