import { TweenLite, Expo } from 'gsap';
import { Default } from './default.js';

export class Termes extends Default {
  constructor(opt) {
    super(opt);

    this.slug = 'gallery';
  }

  init(req, done) {
    super.init(req, done, { sub: req.params.id ? true : false });
  }

  dataAdded(done) {
    super.dataAdded();

    done();
  }

  animateIn(req, done) {
    document.body.classList.add(`is-${this.slug}`);

    TweenLite.to(this.page, 1, {
      y: 0,
      autoAlpha: 1,
      ease: Expo.easeInOut,
      onComplete: done,
    });
  }

  animateOut(req, done) {
    document.body.classList.remove(`is-${this.slug}`);

    TweenLite.to(this.page, 0.7, {
      y: 100,
      autoAlpha: 0,
      ease: Expo.easeInOut,
      clearProps: 'all',
      onComplete: done,
    });
  }

  destroy(req, done) {
    super.destroy();

    this.page.remove();

    done();
  }
}
