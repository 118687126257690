import framework from './framework.js';

window.addEventListener('load', () => {
  framework.init();
});

// Add custom resize event
let timer;
window.addEventListener('resize', () => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    framework.resize(window.innerWidth, window.innerHeight);
  }, 500);
});
