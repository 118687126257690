/* ================================
 * Relation module
 * ================================ */

import TweenMax from 'gsap';
import $ from 'jquery';

export default class RelationLinks {
  constructor() {
    // console.log(`${this.constructor.name} is init`);
    const _ = this;
    _.$sectionText = $('.section--texte');

    _.$sectionText.each(function (i, section) {
      const $section = $(section);
      section.$links = $section.find(`.section__content a`);
      section.$relations = $section.find('.relation');

      _.setUpLinks(section.$links, section.$relations);
      _.bindEvents(section.$links);

      section.$links.each((i, link) => {
        _.setRelationPosition(link, true);
      });
    });

    return _;
  }

  resetPositions() {
    // console.log(`${this.constructor.name} has been repositionned`);
    const _ = this;
    _.$sectionText.each((i, section) => {
      section.$links.each((i, link) => {
        _.setRelationPosition(link);
      });
    });
  }

  bindEvents($links) {
    $links.on({
      mouseenter() {
        this.$relation.addClass('is-active');
      },
      mouseleave() {
        this.$relation.removeClass('is-active');
      },
    });
  }

  setUpLinks($links, $relations) {
    const _ = this;
    $links.each(function (i, link) {
      const url = _.formatURL(link);
      // console.log(url);
      link.$relation = $relations.filter(`[data-url*="${url}"]`);
      link.$relationInner = link.$relation.find('.relation__inner');
      link.__top = 0;
    });
  }

  setRelationPosition(link, noTransition = false) {
    link._sizes = link.getBoundingClientRect();
    try {
      link.$relation[0]._sizes = link.$relation[0].getBoundingClientRect();
    } catch {
      // console.log('No relation anchor link.');
      return;
    }

    link.$relation.addClass('relation--has-link');

    // Compute sizes to align relations to text links
    const offsetTop = $(link).closest('.section--texte')[0].getBoundingClientRect().top;
    const linkSizes = link._sizes;
    const relationSizes = link.$relation[0]._sizes;
    const linkOffsetTop = linkSizes.top - offsetTop;
    const top = linkOffsetTop + linkSizes.height / 2 - relationSizes.height / 2;

    // Check if item is overlapping previous item
    const $prev = link.$relation.prev();
    let modifier = 0;
    if ($prev.length) {
      try {
        const prevTop = Number.parseInt($prev.css('top').replace('px', ''));
        if (top <= prevTop + $prev[0]._sizes.height) {
          modifier += prevTop + $prev[0]._sizes.height - top;
        }
      } catch {
        // console.log(e.message);
      }
    }

    modifier = top + modifier;

    link.$relation.css({
      position: 'absolute',
      top: modifier,
      margin: 0,
    });

    const duration = noTransition ? 0 : 0.8;

    TweenMax.fromTo(
      link.$relationInner,
      duration,
      {
        y: link.__top - modifier,
      },
      {
        y: 0,
        delay: link.$relation.index() / 10,
      },
    );

    link.__top = modifier;
  }

  formatURL(link) {
    let url = link.hostname;
    url += link.port ? `:${link.port}` : '';
    url += link.pathname;
    return url;
  }
}
