import { Home } from './sections/home.js';
import { APropos } from './sections/a-propos.js';
import { MakingOf } from './sections/making-of.js';
import { Exposition } from './sections/exposition.js';
import { Archives } from './sections/archives.js';
import { Contact } from './sections/contact.js';
import { Personnages } from './sections/personnages.js';
import { Personnage } from './sections/personnage.js';
import { Liens } from './sections/liens.js';
import { Lien } from './sections/lien.js';
import { Termes } from './sections/termes.js';
import { Terme } from './sections/terme.js';
import { Livres } from './sections/livres.js';
import { Livre } from './sections/livre.js';
import { Article } from './sections/article.js';

/* ----------
all routes needs to be defined inline
see https://github.com/bigwheel-framework/documentation/blob/master/routes-defining.md#as-section-standard-form
---------- */
export default {
  '/': Home,
  '/a-propos': {
    section: APropos,
  },
  '/making-of': {
    section: MakingOf,
  },
  '/exposition': {
    section: Exposition,
  },
  '/archives': {
    section: Archives,
  },
  '/contact': {
    section: Contact,
  },
  '/personnages': {
    section: Personnages,
    duplicate: true,
  },
  '/personnages/:id': {
    section: Personnage,
    duplicate: true,
  },
  '/liens': {
    section: Liens,
    duplicate: true,
  },
  '/liens/:id': {
    section: Lien,
    duplicate: true,
  },
  '/termes': {
    section: Termes,
    duplicate: true,
  },
  '/termes/:id': {
    section: Terme,
    duplicate: true,
  },
  '/livres': {
    section: Livres,
    duplicate: true,
  },
  '/livres/:id': {
    section: Livre,
    duplicate: true,
  },
  '/:page': {
    section: Article,
  },
};
