import { gsap, TweenLite, Expo, ScrollToPlugin } from 'gsap/all.js';

gsap.registerPlugin(ScrollToPlugin);

// export function arrayFrom(opt) {
//  return Array.prototype.slice.call(opt, 0);
// }

// export function clamp(min, value, max) {
//  return Math.max(min, Math.min(value, max));
// }

// export function scrollTop() {
//  if (window.pageYOffset) return window.pageYOffset;
//  return document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop;
// }

export function smoothScroll(event) {
  const anchor = event.currentTarget;

  if (
    location.pathname.replace(/^\//, '') === anchor.pathname.replace(/^\//, '') &&
    location.hostname === anchor.hostname
  ) {
    const duration = anchor.dataset.duration ?? 1;
    const target = document.querySelector(anchor.hash);

    if (target) {
      TweenLite.to(window, duration, {
        scrollTo: { y: anchor.hash, offsetY: 30 },
        ease: Expo.easeInOut,
      });
      return false;
    }
  }
}

// const utils = {

//  biggie: {

//    addRoutingEL(a) {
//      utils.js.arrayFrom(a).forEach((el) => el.onclick = utils.biggie.handleRoute);
//    },

//    removeRoutingEL(a) {
//      utils.js.arrayFrom(a).forEach((el) => el.onclick = null);
//    },

//    handleRoute(e) {
//      const target = e.currentTarget;
//      if (classes.has(target, 'no-route')) return;

//      e.preventDefault();
//      framework.go(target.getAttribute('href'));
//    },

//    getSlug(req, options) {
//      let route = req.route === '/' ? '/' : req.route;
//      const params = Object.keys(req.params).length === 0 && JSON.stringify(req.params) === JSON.stringify({});

//      if (!params) {
//        for (var key in req.params) {
//          if (req.params.hasOwnProperty(key)) {

//            if (route.indexOf(key) > -1) {
//              route = route.replace(`:${key}`, options.sub ? '' : req.params[key]);
//            }
//          }
//        }
//      }

//      if (route.substring(route.length - 1) === '/') {
//        route = route.slice(0, -1);
//      }

//      return route.substr(1);
//    },

//    createPage(req, slug) {

//      return create({
//        selector: 'div',
//        id: `page-${slug}`,
//        styles: `page page-${slug}`
//      });
//    },

//    loadPage(req, view, done, options) {

//      const slug = utils.biggie.getSlug(req, options);
//      const page = utils.biggie.createPage(req, slug);

//      ajax.get(`/${slug}`, {
//        success: (object) => {
//          const title = object.data.split(/(<title>|<\/title>)/ig)[2]
//          const inner = object.data.split(/<main id="content" role="main" class="content">|<\/main>/ig)[1]
//          page.innerHTML = inner
//          document.title = title.replace(/&amp;/g, '&').replace(/&#8211;/g, '&')
//          done();
//        }
//      })

//      return view.appendChild(page);
//    }
//  }
// }

// export default utils;

export const espaceFine = (match) => {
  return match.replace(' ', '&nbsp;');
};

export const raf = window.requestAnimationFrame ?? ((callback) => setTimeout(callback, 1000 / 60));

export const resizeImage = (url, options) => {
  if (url.endsWith('.gif')) {
    return url;
  }

  const originalUrl = new URL(url, window.location.origin);
  const cdnUrl = new URL(`/alloayiti${originalUrl.pathname}`, 'https://cdn.studiometa.fr');

  cdnUrl.search = `twic=v1/${options.fit ?? 'cover'}=${options.w}x${options.h ?? options.w}`;

  return cdnUrl.href;
};
