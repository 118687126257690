import { Default } from './default.js';

export class Home extends Default {
  constructor(opt) {
    super(opt);

    this.slug = 'home';
    this.ui = null;
  }

  init(req, done) {
    super.init(req, done);
  }

  dataAdded(done) {
    super.dataAdded();

    done();
  }

  animateIn() {
    document.body.classList.add(`is-${this.slug}`);

    // TweenLite.to(this.page, 1, {
    //  y: 0,
    //  autoAlpha: 1,
    //  ease: Expo.easeInOut,
    //  onComplete: done
    // })
  }

  animateOut() {
    document.body.classList.remove(`is-${this.slug}`);

    // TweenLite.to(this.page, 0.7, {
    //  y: 100,
    //  autoAlpha: 0,
    //  ease: Expo.easeInOut,
    //  onComplete: done
    // })
  }

  destroy(req, done) {
    super.destroy();

    this.ui = null;

    // this.page.parentNode.removeChild(this.page)

    done();
  }
}
