import { Default } from './default.js';

export class APropos extends Default {
  constructor(opt) {
    super(opt);
    this.slug = 'a-propos';
  }

  init(req, done) {
    super.init(req, done);
  }

  dataAdded(done) {
    super.dataAdded();
    done();
  }

  animateIn(req, done) {
    super.animateIn(req, done);
  }

  animateOut(req, done) {
    super.animateOut(req, done);
  }

  destroy(req, done) {
    super.destroy();
    done();
  }
}
