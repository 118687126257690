import config from '../config.js';
import Blazy from 'blazy';
import $ from 'jquery';
import { smoothScroll, espaceFine, raf, resizeImage } from '../utils.js';

/**
 * Default.
 */
export class Default {
  /**
   * @param {Record<string, any>} [opt]
   */
  constructor(opt = {}) {
    console.log(`${this.constructor.name}:constructor`, opt);
    this.scrollTop();
  }

  /**
   * @param {any} req
   * @param {Function} done
   */
  init(req, done) {
    console.log(`${this.constructor.name} is init`);

    if (config.isMac) $('html').addClass('is-mac');

    this.selector = '.js-lazy';
    this.firstResize = false;

    $(this.selector).each((index, image) => {
      image.dataset.blank = image.src;
      image.dataset.original = image.dataset.src;
      image.dataset.src = this.getSrc(image);
    });

    const onLazySuccess = this.onLazySuccess.bind(this);
    this.lazy = new Blazy({
      selector: this.selector,
      successClass: 'is-loaded',
      offset: window.innerHeight,
      success: onLazySuccess,
    });

    // Smooth scroll for anchors link
    config.$document.on('click', 'a[href^="#"]:not([href="#"])', smoothScroll);

    $('.js-espace-fine').each((i, el) => {
      const $el = $(el);
      let content = $el.html();
      content = content.replaceAll(/\s(\?|!|;|:)/g, espaceFine);
      $el.html(content);
    });

    done();
  }

  onLazySuccess(image) {
    const $image = $(image);
    if ($image.hasClass('image__image')) {
      $image.closest('.image').addClass('is-loaded');
    }
    $(this.lazy).trigger('lazy:load');
  }

  animateIn(req, done) {
    config.$body.addClass(`is-${this.slug}`);
    done();
  }

  animateOut(req, done) {
    config.$body.removeClass(`is-${this.slug}`);
    done();
  }

  resize(width, height) {
    console.log(`${this.constructor.name} has been resized`);
    config.height = height;
    config.width = width;

    if (this.firstResize) {
      $(this.selector).each((index, image) => {
        if ('src' in image.dataset) {
          image.dataset.src = this.getSrc(image);
        } else {
          image.classList.remove('is-loaded');

          const $image = $(image);
          if ($image.hasClass('image__image')) {
            $image.closest('.image').removeClass('is-loaded');
          }

          if (image.tagName === 'IMG') {
            image.src = image.dataset.blank;
          }

          const url = this.getSrc(image);

          const img = document.createElement('img');
          img.addEventListener('load', () => {
            if (image.tagName === 'IMG') {
              image.src = url;
            } else {
              image.style.backgroundImage = `url(${url})`;
            }

            setTimeout(() => {
              image.classList.add('is-loaded');
              this.onLazySuccess(image);
            }, 100);
          });
          img.src = url;
        }
      });
    }

    this.firstResize = true;
  }

  destroy(req, done) {
    console.log(`${this.constructor.name} has been destroyed`);
    done();
  }

  getSrc(image) {
    const sizes = image.getBoundingClientRect();
    const width = Math.ceil(sizes.width / 100) * 100;
    const height = image.dataset.ratio
      ? Math.round(width / image.dataset.ratio)
      : Math.round(Math.ceil(sizes.height / 100) * 100);
    const fit = image.dataset.fit || 'cover';
    const url = image.dataset.original;
    const resizedUrl = resizeImage(url, {
      w: width,
      h: height,
      q: 90,
      fit,
    });

    return resizedUrl;
  }

  scrollTop() {
    console.log('scrollTop');

    const $button = $('.back-top');
    let pos = window.pageYOffset;

    /**
     *
     */
    function loop() {
      const newPos = window.pageYOffset;
      if (pos === newPos) {
        raf(loop);
        return;
      }
      const direction = newPos > pos ? 'down' : 'up';
      const diff = newPos - pos;

      if (direction === 'up' && diff < -10 && !$button.hasClass('is-visible')) {
        $button.addClass('is-visible');
      }

      if (
        (direction === 'down' && diff > 10 && $button.hasClass('is-visible')) ||
        (newPos < window.innerHeight / 2 && $button.hasClass('is-visible'))
      ) {
        $button.removeClass('is-visible');
      }

      pos = newPos;
      raf(loop);
    }

    loop();
  }
}
