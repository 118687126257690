import { raf } from '../utils.js';
import { TweenLite, Expo, TweenMax } from 'gsap';
// import DrawSVG from '../vendors/DrawSVGPlugin.min.js'
import $ from 'jquery';
import { Default } from './default.js';
import Flickity from 'flickity';
import { Howl } from 'howler';
// import WaveSurfer from 'wavesurfer.js'
import RelationLinks from '../utils/relations.js';

TweenLite.defaultEase = Expo.easeOut;

export class Article extends Default {
  constructor(opt) {
    super(opt);
    this.slug = 'article';
    this.sliders();
    this.audio();
    this.photoswipe();
    this.rolloverAudio();
    this.relationLinks = new RelationLinks();
    this.panel = new Panel();
  }

  init(req, done) {
    super.init(req, done);
    $(this.lazy).on('lazy:load', () => {
      this.relationLinks.resetPositions();
    });
    $(this.panel).on({
      'panel:content': () => {
        console.log('panel:content');
        this.panel.$panel.find(this.selector).each((index, image) => {
          image.dataset.blank = image.src;
          image.dataset.original = image.dataset.src;
          image.dataset.src = this.getSrc(image);
        });
      },
      'panel:contentShow': () => {
        this.lazy.revalidate();
      },
    });

    done();
  }

  resize(width, height) {
    super.resize(width, height);
    this.relationLinks.resetPositions();
  }

  animateIn(req, done) {
    done();
  }

  animateOut(req, done) {
    done();
  }

  destroy(req, done) {
    super.destroy();
    done();
  }

  sliders() {
    const $sliders = $('.js-slider');
    $sliders.each(function (i, slider) {
      this._slider = new Flickity(slider, {
        prevNextButtons: false,
      });
    });
  }

  audio() {
    const _ = this;
    const $audios = $('.audio');

    $audios.each((i, audio) => {
      const $audio = $(audio);
      const $progress = $audio.find('.audio__progress');
      const $current = $progress.find('.audio__current use');
      const $play = $audio.find('.audio__btn--play');
      const $pause = $audio.find('.audio__btn--pause');
      const $stop = $audio.find('.audio__btn--stop');
      const src = $audio.data('src');

      audio.id = `audio-${_.guidGenerator()}`;
      audio.isPlaying = false;
      const howl = (audio.howl = new Howl({
        src: [src],
        html5: true,
        onend: () => {
          console.log(`onend: ${$audio}`);
          audio.isPlaying = false;
          TweenMax.to($current, 0.3, {
            strokeDashoffset: 0,
            ease: Expo.easeOut,
          });
        },
      }));

      TweenMax.set($current, { strokeDashoffset: 500 });

      audio._play = () => {
        console.log(`play: ${$audio}`);
        $audios.each((i, audio) => {
          if (audio.isPlaying) {
            console.log(audio);
            audio._pause();
          }
        });
        howl.play();
        audio.isPlaying = true;
        loop();
        $pause.prop('disabled', false);
        $stop.prop('disabled', false);
        $play.prop('disabled', true);
      };

      audio._pause = () => {
        console.log(`pause: ${$audio}`);
        howl.pause();
        audio.isPlaying = false;
        $pause.prop('disabled', true);
        $stop.prop('disabled', false);
        $play.prop('disabled', false);
      };

      audio._stop = () => {
        console.log(`stop: ${$audio}`);
        howl.stop().seek(0);
        audio.isPlaying = false;
        $pause.prop('disabled', true);
        $stop.prop('disabled', true);
        $play.prop('disabled', false);
        TweenMax.set($current, { strokeDashoffset: 500 });
      };

      $progress.on('click', (e) => {
        const sizes = $progress[0].getBoundingClientRect();
        const percent = ((e.pageX - sizes.left) * howl.duration()) / sizes.width;
        howl.seek(percent);
        const pos = getPos();
        TweenMax.set($current, { strokeDashoffset: 500 - pos });
      });

      $play.on('click', audio._play);
      $pause.on('click', audio._pause);
      $stop.on('click', audio._stop);

      /**
       *
       */
      function loop() {
        if (!audio.isPlaying) return;
        const pos = getPos();
        TweenMax.set($current, { strokeDashoffset: 500 - pos });
        raf(loop);
      }

      /**
       *
       */
      function getPos() {
        return Math.round((howl.seek() * 500) / howl.duration());
      }
    });
  }

  guidGenerator() {
    const S4 = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).slice(1);
    };
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4() + S4() + S4()}`;
  }

  photoswipe() {
    console.log('photoswipe');
  }

  rolloverAudio() {
    const $audio = $('.js-audio-rollover');

    $audio.each((i, audio) => {
      const $audio = $(audio);
      const src = $audio.data('src');
      let isPlaying = false;
      const howl = new Howl({
        src: [src],
        html5: true,
        loop: true,
        preload: true,
        volume: 0,
      });

      $audio.on({
        mouseenter: () => {
          // Do nothing if already playing
          if (isPlaying) return;
          isPlaying = true;
          howl.play();
          howl.fade(0, 1, 200);
        },
        mouseleave: () => {
          // Do nothing if already pausing
          if (!isPlaying) return;
          howl.once('fade', () => {
            isPlaying = false;
            howl.pause();
          });
          howl.fade(1, 0, 200);
        },
      });
    });
  }
}

class Panel {
  constructor() {
    this.cache = {};

    this.isOpen = false;
    this.$panel = $('#js-panel');
    this.$panelInner = $('#js-panel__inner');
    this.$panelContent = $('#js-panel__content');
    this.$btnClose = $('#js-panel__close');

    TweenMax.set(this.$panelContent, { opacity: 0 });

    this.regex = new RegExp(/termes|livres|personnages/);
    this.$links = $('a').filter((i, link) => {
      if (link.host !== location.host) return false;
      if ($(link).hasClass('menu__link')) return false;
      const segments = link.pathname.split('/');
      $.each(segments, (i, segment) => {
        if (segment === '') segments.splice(i, 1);
      });
      return this.regex.test(segments[0]);
    });

    this.$links.on('click', (e) => {
      if (!(e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();
        this.open(e.currentTarget.href);
      }
    });

    $(document).on('click', () => {
      console.log('document click', this.isOpen);
      if (this.isOpen) {
        this.close();
      }
    });

    this.$btnClose.on('click', this.close.bind(this));
    this.$panel.on('click', (e) => {
      e.stopPropagation();
    });
  }

  open(url) {
    console.log('open', url);
    this.isOpen = true;
    this.getContent(url);
  }

  close() {
    console.log('close');
    this.isOpen = false;

    TweenMax.to(this.$panelInner, 0.8, {
      x: 0,
      ease: Expo.easeInOut,
      onComplete: () => {
        $(this).trigger('panel:close');
        TweenMax.set(this.$panelContent, {
          opacity: 0,
        });
      },
    });
  }

  getContent(url) {
    console.log('getContent', url);

    console.log(this.cache);

    TweenMax.to(this.$panelInner, 0.6, {
      x: -this.$panelInner.outerWidth(),
      ease: Expo.easeOut,
    });
    this.$panel.addClass('is-loading');

    if (this.cache[url] && this.cache[url].length) {
      console.log('from cache');
      this.appendContent(url);
      return;
    }

    $.ajax({
      url,
      cache: false,
    })
      .done((data) => {
        console.log('getContent:done');
        this.cache[url] = data;
      })
      .fail(() => {
        console.log(arguments);
      })
      .always(() => {
        console.log('getContent:complete');
        this.appendContent(url);
      });
  }

  appendContent(url) {
    console.log('appendContent');
    this.$panelContent.html(this.cache[url]);
    $(this).trigger('panel:content');
    this.$panel.removeClass('is-loading');

    TweenMax.to(this.$panelContent, 0.6, {
      opacity: 1,
      ease: Expo.easeOut,
      onComplete: () => {
        $(this).trigger('panel:contentShow');
      },
    });
  }
}
